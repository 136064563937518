.footer {
  position: fixed;
  background: #ececec;
  width: 100%;
  padding: 6px 0;
  bottom: 0;
  // text-align: center;
  .text {
    color: #3e4652;
    .link {
      color: #00b4b6 !important;
    }
  }
}
