@import "animate.css";
@import url(https://fonts.googleapis.com/css?family=Roboto:500);

* {
  padding: 0;
  margin: 0;
  // list-style: none;
  outline: none;
  font-family: "Nunito", Roboto, system-ui, Tahoma, sans-serif;
  box-sizing: border-box;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a {
  text-decoration: none !important;
  color: #3e4652 !important;
}
.text-b {
  color: #3e4652 !important;
}
.text-blue {
  color: #00b4b6 !important;
}
.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
// #004059
// #006576
// #008b8c
// #f1efc7
// #f19a75

.logo-label {
  color: #00b4b6 !important;
}

.main-page {
  li {
    font-size: 20px;
    font-weight: bold;
    color: white;
    padding: 20px;
    margin: 20px auto;
    text-align: center;
    border-radius: 10px;
    background: #3e4652;
  }
}

.privacy {
  color: #3e4652 !important;
}
.order-container {
  .btn-buyer {
    border-color: #00b4b6 !important;
  }
}
.offcanvas-md {
  background-color: #3e4652 !important;
}
.shop-card {
  max-width: 600px;
}
.privacy {
  ul {
    padding: 0;
  }
  li {
    padding: 5px 0;
  }
}
.nomenclature-description {
  border: solid 1px #3e4652;
}

// Гугловская кнопка
.google-btn {
  transition: 0.2s;
  cursor: pointer;
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 50px;
    height: 50px;
    border-radius: 2px;
    background-color: white;
  }
  .google-icon {
    width: 35px;
    height: 35px;
  }
  .btn-text {
    font-size: 21px !important;
    font-family: "Roboto";
  }
  &:hover {
    scale: 1.05;
  }
  &:active {
    background: white;
  }
}
